.Container {
  font-family: "Roboto";
  width: 99, 9vw;
  height: max-content;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  width: 100%;
  height: fit-content;
}

.WordList {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  margin-left: 50px;
}

.Logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 580px;
  position: sticky;
  margin-top: 200px;
  top: 40vh;
  height: fit-content;
  padding-left: 150px;
}

.Header {
  z-index: 2;
  position: sticky;
  width: 100%;
  height: 100px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  background-color: #086cb4;
  color: white;
  border-style: solid;
  border-bottom-width: 2px;
  border-color: black;
}

.toast {
  margin-top: 80px;
}

.button {
  margin-top: 10px;
  width: 580px;
  height: 80px;
  background-color: rgb(0, 155, 0);
  transition: background-color 0.5s;
  color: white;
  font-size: 2rem;
  border: solid;
  border-radius: 10px;
  border-color: black;
  border-width: 1px;
}

.button:hover {
  background-color: rgb(5, 177, 5);
}

.button:disabled {
  background-color: #cccccc;
}

.TestHeader {
  position: sticky;
  display: flex;
  background-color: white;
  width: 620px;
  height: 70px;
  top: 80px;
  padding-top: 30px;
  display: flex;
  font-size: 1.8rem;
  font-weight: bold;
  padding-left: 10px;
  margin-bottom: 5px;
  padding-left: 40px;
  margin-top: 25px;
  border-bottom: solid;
  border-width: 2px;
  z-index: 1;
}

.WordHeader {
  position: relative;
  right: 70px;
}

.TestHeaderWordPositiv {
  position: relative;
  left: 270px;
}

.TestHeaderWordNegativ {
  position: relative;
  left: 300px;
}

.LogoPicture {
  width: 500px;
  height: 197px;
  background-image: url("../assets/images/bifo-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.Information {
  position: absolute;
  right: 20px;
}

.goodWords {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ee7f00;
  width: 50%;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  font-size: 1.5rem;
}

.badWords {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #086cb4;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 50%;
  height: 100%;
  color: white;
  font-size: 1.5rem;
}

.wordsSelection {
  display: flex;
  margin-top: 10px;
  width: 580px;
  height: 80px;
  border: solid;
  border-width: 1px;
  border-radius: 11px;
}

@media all and (max-width: 1400px) {
  .goodWords {
    font-size: 1rem;
  }
  .badWords {
    font-size: 1rem;
  }
  .Logo {
    padding-left: 100px;
  }

  .LogoPicture {
    width: 350px;
    height: 160px;
  }

  .button {
    width: 320px;
    margin-bottom: 100px;
  }

  .NextButton {
    top: 60vh;
    height: 90px;
  }

  .wordsSelection {
    display: flex;
    width: 320px;
    height: 70px;

    text-align: center;
  }
}

@media all and (max-width: 1000px) {
  .Header {
    width: 100%;
  }
  .main {
    flex-direction: column;
    align-items: center;
  }

  .WordList {
    position: relative;
    margin: 0px;
  }

  .LogoPicture {
    width: 0px;
    height: 0px;
    background-image: none;
  }

  .Logo {
    bottom: 0px;
    background-color: white;
    width: 100%;
    height: 180px;
    margin: 0px;
    padding: 0px;
    border-top: solid;
    border-top-width: 2px;
  }

  .button {
    width: 580px;
  }

  .wordsSelection {
    margin-top: 10px;
    display: flex;
    width: 580px;
    font-size: 1.5rem;
    text-align: center;
  }
}

@media all and (max-width: 730px) {
  .Header {
    font-size: 2.5rem;
    height: 70px;
    width: 100%;
  }

  .main {
    width: 100%;
  }

  .WordList {
    width: 85%;
  }

  .TestHeader {
    font-size: 1.3rem;
    right: 0px;
    top: 60px;
    height: 60px;
    width: 540px;
    margin-top: 10px;
  }

  .WordHeader {
    right: 70px;
  }

  .TestHeaderWordPositiv {
    left: 220px;
  }
  .TestHeaderWordNegativ {
    left: 280px;
  }

  .button {
    width: 500px;
    height: 50px;
    font-size: 1.5rem;
    margin-bottom: 0px;
  }

  .Logo {
    height: 140px;
  }
  .wordsSelection {
    font-size: 1rem;
    width: 500px;
    margin-top: 0px;
    height: 50px;
  }
  .NextButton {
    top: 60vh;
    height: 70px;
  }
  .Information {
    padding-bottom: 5px;
    font-size: 1em;
  }

  .toast {
    margin-top: 50px;
  }
}

@media all and (max-width: 610px) {
  .WordList {
    position: unset;
    width: 100%;
  }

  .goodWords {
    border-radius: 0px;
  }

  .badWords {
    border-radius: 0px;
  }

  .Header {
    font-size: 1.8rem;
    height: 70px;
    font-size: 2.2rem;
  }

  .TestHeader {
    position: sticky;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    top: 60px;
    height: 50px;
    margin: 0;
    padding-left: 0px;
    padding-top: 15px;
  }

  .WordHeader {
    right: 0px;
    left: 5px;
    width: 230px;
    text-align: center;
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .TestHeaderWordPositiv {
    position: relative;
    left: 10px;
    font-size: 1.5rem;
  }

  .TestHeaderWordNegativ {
    position: relative;
    left: -10px;
    font-size: 1.5rem;
  }

  .button {
    height: 60px;
    font-size: 1.5rem;
    margin-bottom: 0px;
    margin-top: 5px;
    width: 100%;
    border-radius: 0px;
  }

  .Logo {
    height: 125px;
  }
  .wordsSelection {
    font-size: 1.5rem;
    height: 60px;
    width: 100%;
    margin-top: 0px;
    border-radius: 0px;
  }
  .NextButton {
    top: 60vh;
    height: 65px;
    width: 100%;
  }

  .Information {
    padding-bottom: 5px;
    font-size: 1.5em;
  }

  .toast {
    margin-top: 40px;
  }
}

@media all and (max-width: 500px) {
  .Header {
    font-size: 1.8rem;
    height: 60px;
  }

  .TestHeader {
    top: 50px;
    height: 40px;
    padding-top: 10px;
  }

  .WordHeader {
    width: 160px;
  }
  .Information {
    padding-bottom: 5px;
    font-size: 1.5em;
  }

  .toast {
    margin-top: 60px;
  }
}

@media all and (max-width: 420px) {
  .Header {
    height: 70px;
    justify-content: space-between;
  }

  .Information {
    padding-bottom: 5px;
    font-size: 1.5em;
  }

  .wordsSelection {
    font-size: 1.2rem;
    height: 60px;
    width: 100%;
    margin-top: 5px;
  }
  .TestHeader {
    top: 60px;
    height: 40px;
    padding-top: 10px;
  }
  .TestHeaderWordPositiv {
    font-size: 1rem;
  }

  .TestHeaderWordNegativ {
    font-size: 1rem;
  }

  .WordHeader {
    font-size: 1rem;
  }

  .Title {
    position: relative;
    left: 10px;
    width: 310px;
    line-height: normal;
    margin-right: 70px;
  }

  .goodWords {
    font-size: 1rem;
  }

  .badWords {
    font-size: 1rem;
  }

  .toast {
    margin-top: 70px;
  }
}
