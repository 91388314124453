.main {
  height: 210mm;
  width: 297mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  border-style: solid;
  border-width: 2px;
  width: 50px;
  text-align: center;
  height: 22px;
  vertical-align: middle;
}

.logo {
  position: absolute;
  top: 10px;
  left: 245mm;
  background-image: url("../assets/images/bifo-logo.png");
  height: 65px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat;
}
.upperHalf {
  width: 200mm;
  height: 6.5mm;
}

.hell {
  margin-top: 10px;
  width: 95%;
  height: 170mm;
  font-size: 0.879rem;
}

.headers {
  border-style: solid;
  border-width: 2px;
  border-color: black;
  width: 280mm;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  color: white;
}

.heaven {
  display: flex;
  align-items: center;
  height: 20mm;
  width: 95%;
  font-weight: bold;
  font-size: 2rem;
}

.content {
  vertical-align: middle;
  border-style: solid;
  border-width: 2px;
  height: 5mm;
  font-size: 0.839rem;
}
