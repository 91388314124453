.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 580px;
  border-radius: 15px;
  margin-bottom: 15px;
  background-color: #e9e9e9;
  box-shadow: -2px 3px 10px;
}

.labels {
  width: 60px;
  position: relative;
}
.word {
  position: relative;
  right: 165px;
  background-color: transparent;
  justify-content: center;
  width: 250px;
  height: 50px;
  font-size: 1.3rem;
  padding-left: 10px;
  padding-top: 15px;
  border-radius: 15px;
  transition: background-color 0.5s;
}

.radioPositive {
  position: relative;
  left: 290px;
}

.radioNegative {
  position: relative;
  right: 45px;
}
.radios {
  display: flex;
  width: 100%;
}

.radio {
  margin-top: 5px;
  background-color: transparent;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 18px;
  margin-right: 60px;
}

@media all and (max-width: 730px) {
  .word {
    font-size: 1rem;
    height: 40px;
    width: 200px;
    padding-top: 10px;
  }

  .container {
    height: 40px;
    width: 500px;
  }

  .radio {
    height: 1.2rem;
    width: 1.2rem;
    margin-left: 20px;
  }

  .radioPositive {
    left: 230px;
  }

  .radioNegative {
    position: relative;
    right: 35px;
  }
}

@media all and (max-width: 610px) {
  .container {
    width: 100vw;
    height: 60px;
    justify-content: space-between;
    border-radius: 0px;
    margin-bottom: 20px;
  }
  .word {
    position: relative;
    font-size: 1.6rem;
    height: 100%;
    width: 230px;
    padding: 0px;
    padding-top: 15px;
    text-align: center;
    left: 0px;
  }

  .radioPositive {
    position: relative;
    margin: 0px;
    padding: 0px;
    left: 25px;
  }

  .radioNegative {
    position: relative;
    margin: 0px;
    padding: 0px;
    left: -5px;
  }
  .radio {
    height: 2rem;
    width: 2rem;
    padding: 0px;
    margin: 0px;
    margin-top: 5px;
  }
}

@media all and (max-width: 420px) {
  .labels {
    width: 40px;
  }

  .word {
    position: relative;
    font-size: 1.1rem;
    height: 100%;
    width: 150px;
    padding: 0px;
    padding-top: 15px;
    text-align: center;
    left: 0px;
  }

  .container {
    width: 100vw;
    height: 50px;
    justify-content: space-between;
  }

  .radio {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0px;
    margin: 0px;
    margin-top: 5px;
  }

  .radioPositive {
    position: relative;
    margin: 0px;
    padding: 0px;
    left: 20px;
  }

  .radioNegative {
    position: relative;
    margin: 0px;
    padding: 0px;
    left: -10px;
  }
}
