@media all {
  .button {
    width: 580px;
    height: 80px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
