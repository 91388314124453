.main {
  height: 210mm;
  width: 297mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  position: absolute;
  top: 15px;
  left: 242mm;
  background-image: url("../assets/images/bifo-logo.png");
  height: 65px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat;
}

.header {
  display: flex;
  align-items: center;
  height: 25mm;
  width: 95%;
  font-weight: bold;
  font-size: 2rem;
}

.tableWrapper {
  width: 95%;
  height: 170mm;
  font-size: 0.879rem;
}
.tableContainer {
  width: 100%;
}

.checkers {
  border: 1px;
  width: 6mm;
  height: 6mm;
  border-style: solid;
}

.row {
  width: 10mm;
  border-right: 1px;
  border-style: solid;
  position: relative;
  padding-left: 5px;
}

.group {
  border: 2px;
  border-style: solid;
}

.border {
  background-color: green;
  height: 6mm;
  width: 6mm;
  border-top-width: 1px;
  z-index: -98;
}

.sentence {
  padding-left: 5px;
}

.title {
  position: absolute;
  left: 240mm;
  font-weight: bold;
  width: 45mm;
  z-index: -99;
}

.description {
  position: relative;
  display: flex;
  align-items: center;
}
