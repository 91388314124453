.main {
  border-top: 1px;
  border-style: solid;
  height: 40px;
  width: 100%;
  display: grid;
  grid-template-columns: 100px 110px 140px 150px 5px;
  align-items: center;
  justify-content: space-around;
  z-index: -1;
}

.delete {
  position: relative;
  right: 130px;
  color: rgb(170, 0, 0);
  font-size: 1.2rem;
  cursor: pointer;
}

.delete:hover {
  color: rgb(228, 100, 100);
}

.download {
  position: relative;
  left: 20px;
  font-size: 1.2rem;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.download:hover {
  color: rgb(63, 179, 236);
}
