.Container {
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #086cb41a;
}

.Logo {
  position: relative;
  top: 20px;
  width: 250px;
  height: 70px;
  background-position: center;
  background-position-y: top;
  background-image: url("../assets/images/bifo-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom: 1px solid;
}

.Background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  border-radius: 5px;
  box-shadow: 7px 7px 10px 1px;
  height: 430px;
  width: 350px;
}

#UserInput {
  border: 1px solid;
  border-radius: 10px;
}

.Username {
  position: relative;
}

.Passwort {
  position: relative;
  bottom: 20px;
}

.inputField {
  font-size: 20px;
  border: none;
  border-radius: 0px;
  height: 35px;
  width: 200px;
  border-bottom: 1px solid;
  outline: none;
}
.errMsg {
  position: relative;
  color: red;
  bottom: 40px;
}

.Butt {
  position: relative;
  background-color: rgb(255, 165, 0);
  height: 50px;
  width: 190px;
  bottom: 40px;
  border: none;
  border-radius: 2px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.Butt:hover {
  background-color: rgb(245, 182, 67);
}
