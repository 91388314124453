@media all {
  .modalFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .buttonWidth {
    min-width: 100%;
    width: 100%;
    font-weight: bold;
  }

  .buttonWidth2 {
    min-width: 49%;
    width: 49%;
    font-weight: bold;
  }

  .modalHeader {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .Title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }

  .introTitle {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }

  .borderRadius {
    border-radius: 10px;
    border-style: solid;
  }

  .error {
    width: 300px;
    color: red;
  }

  .underline {
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 10px;
    text-decoration-line: underline;
  }

  .image {
    width: 100%;
  }

  .searchBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .searchInput {
    width: 30%;
    height: 38px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #086cb4;
    margin-right: 10px;
  }

  .loadingMsg {
    font-size: 3vmin;
    position: relative;
    margin-top: 10px;
  }

  .loadingImage {
    width: 7vmin;
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
    text-align: center;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;

    width: 120px;
    height: 120px;
  }

  .clipBoard {
    position: relative;
    left: 10px;
    bottom: 2px;
    font-size: 1.3rem;
    cursor: pointer;
  }

  .clipBoard:hover {
    color: gray;
  }

  .createdLink {
    text-decoration-line: underline;
    font-size: 1.2rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 15vh;
    text-align: center;
  }

  .IntroInformation {
    line-height: 2.5;
    margin-bottom: 15px;
    text-align: center;
  }

  b {
    font-weight: bold !important;
    text-decoration-line: underline;
  }

  p {
    line-height: 1.5 !important;
  }

  .RotationNoti {
    background-color: white;
  }
}
@media all and (max-width: 990px) {
  .IntroInformation {
    line-height: 1.3;
    margin-bottom: 15px;
  }

  .introTitle {
    font-size: 1.6rem;
    font-weight: bold;
  }
}
