.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.errorNumber {
  font-size: 15vmin;
  font-weight: bold;
}

.errorMessage {
  font-size: 6vmin;
}

.errorSubMessage {
  position: relative;
  font-size: 4vmin;
}
