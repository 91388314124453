.Container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/images/AdminBackground.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
}

.Logo {
  position: relative;
  right: 105px;
  background-image: url("../assets/images/bifo-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 80px;
  width: 250px;
}

.Knöpfe {
  position: relative;
  margin-top: 60px;
  left: 97px;
  display: flex;
  width: 230px;
  justify-content: space-around;
}

.Header {
  margin-top: 30px;
  position: sticky;
  width: 80%;
  height: 100px;
  background-color: transparent;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Headline {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 20px;
}

.Knopf {
  font-weight: bold;
  font-size: 1.2rem;
  background: none;
  border: none;
  padding: 0;
  color: #d68d04;
  cursor: pointer;
}

.LogOut {
  position: relative;
  bottom: 6px;
  font-size: 1.7rem;
  color: #d68d04;
  cursor: pointer;
}

.LogOut:hover {
  color: #ffb836;
  text-decoration: underline;
}

.Knopf:hover {
  color: #ffb836;
  text-decoration: underline;
}

.List {
  position: relative;
  top: 20px;
  height: 695px;
  width: 700px;
  background-color: #e0e0e0;
  border: solid;
  border-radius: 10px;
  border-width: 1px;
  box-shadow: 0 0 20px rgb(160, 160, 160);
}

.removeFilter {
  position: relative;
  font-size: 2rem;
  right: 15px;
  cursor: pointer;
}

.Navigation {
  display: grid;
  grid-template-columns: 100px 110px 130px 110px 5px 10px;
  align-items: center;
  font-weight: bold;
  font-size: 1.3rem;
  justify-content: space-around;
  height: 50px;
  background-color: #086cb4;
  color: white;
  margin-bottom: 0px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: black;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 10px;
}

.pageNumber {
  margin-top: 1px;
  display: flex;
  justify-content: center;
  width: 70px;
}

.button {
  font-size: 1.4rem;
  background: none;
  border: none;
  padding: 0;
  color: #069;
  cursor: pointer;
}

.listHeader {
  cursor: pointer;
}

.SearchIcon {
  position: relative;
  right: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

@media all and (max-height: 900px) {
  .Container {
    height: 900px;
  }
}
